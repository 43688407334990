.block-head {
  border-bottom: 1px solid var(--border-color);
  padding: 6px 16px;
  min-height: 56px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 991px) {
    padding: 15px 16px;
  }

  &__action_btns {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .header-left-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .header-btn-back {
    width: 24px;
    cursor: pointer;

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .header-right-items {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .header_section_subtitle {
    font-size: 12px;
    line-height: 15px;
    color: #9c9fa1;
  }

  .header-right-icon {
    opacity: 0.6;
    cursor: pointer;

    &.bg-blue {
      opacity: 1;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary);
    }

    &:hover {
      opacity: 1;
    }

    &.dropdown-opener {
      margin: 0 -5px 0 0;
      padding: 5px;
      transition: all 0.4s ease;
      border-radius: 8px;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      svg {
        display: block;
      }
    }
  }

  .dropdown-opener {
    &.vertical {
      width: 14px;
      height: 28px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: var(--primary);
      }
    }
  }
}
