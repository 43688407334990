.rc-vidoe-player {
  position: relative;

  &:hover {
    .custom-video-control {
      &.video-pause-icon {
        opacity: 1;
        visibility: visible;
      }
    }

    .custom-video-progress-bar-container {
      opacity: 1;
      visibility: visible;
    }
  }
}

.custom-video-control {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  cursor: pointer;
  transition: all 0.4s ease;

  &.video-pause-icon {
    opacity: 0;
    visibility: hidden;

    svg {
      padding: 14px;
    }
  }

  svg {
    width: 100%;
    height: auto;
    display: block;
  }
}

.custom-video-progress-bar-container {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 5px;
  height: 5px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.2);
  z-index: 3;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;

  .custom-video-progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    height: 5px;
    transition: all 0.4s ease;
  }
}
