.header-bookmark {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  padding: 16px 24px 15px 16px;
  font-weight: 500;

  .filters-header-bar {
    padding: 0;
    min-height: inherit;
  }
}

.add-bookmark-modal {
  &.modal-style {
    max-width: 340px;

    .card-modal-wrap {
    }
  }

  .collection-list-item {
    position: relative;
    border: none;
    padding: 9px 16px 9px 52px;
    display: flex;
    align-items: center;
    transition: all 0.4s ease;
    overflow: hidden;
    background: none;
    min-height: 72px;

    &:hover {
      background: none;

      &:before {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:after {
      position: absolute;
      left: 52px;
      right: 0;
      bottom: 0;
      content: '';
      height: 1px;
      background: var(--border-color);
    }

    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
    }

    &__title {
      font-weight: 500;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      gap: 20px;
      overflow: hidden;
      color: #9c9fa1;

      li {
        position: relative;

        &:before {
          position: absolute;
          left: -12px;
          top: 50%;
          transform: translate(0, -50%);
          width: 3px;
          height: 3px;
          border-radius: 100%;
          content: '';
          background: var(--light-gray-200);
        }
      }
    }

    &__images-list {
      display: flex;
      flex-direction: row;
      padding: 0 24px 0 0;

      .user-image {
        width: 52px;
        height: 52px;
        border-radius: 100%;
        border: 2px solid var(--default-bg);
        overflow: hidden;
        margin: 0 -24px 0 0;
      }
    }
  }

  &.has-space {
    .rc-scollbar {
      padding: 10px 0;
    }
  }

  .collection-list-item__left {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .custom-checkbox-label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 3;

    .custom-checkbox-text {
      display: none;
    }
  }

  .collection-list-item__images-list {
    padding: 0 18px 0 0;

    .user-image {
      width: 36px;
      height: 36px;
      margin: 0 -18px 0 0;
    }
  }

  .card-modal-wrap {
    padding: 0;

    &.has-height {
      height: 283px;
    }
  }

  .custom-checkbox-label {
    &:before {
      position: absolute;
      top: -20px;
      bottom: -20px;
      left: -50px;
      right: -1000px;
      content: '';
    }
  }
}

.modal-footer-newUser {
  border-top: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 2px;

  .button {
    padding: 7px 14px;
    color: var(--link-hover);
    border-radius: 16px;

    &.modal-btn-cancel {
      opacity: 0.6;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      opacity: 1;
    }
  }
}

.profile-card-modal {
  &.modal-dialog {
    max-width: 358px;
  }

  .profile_favourite_card {
    border: none;
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    &__bg-img {
      height: 110px;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__text-holder {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;
      padding: 0 0 8px;
    }

    &__title {
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 8px;

      &_text {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
      }

      svg {
        width: 16px;
        min-width: 16px;
        height: auto;
        display: block;
      }
    }

    &__available {
      position: absolute;
      bottom: 10px;
      left: 136px;
      z-index: 2;
      font-weight: 500;
      color: var(--white);
    }

    &__user-name {
      font-weight: 500;
      color: #d0cbc7;
      opacity: 0.8;

      #light_mode & {
        color: var(--default-text-color);
      }
    }

    &__details-wrap {
      padding: 0 16px 6px;
      display: flex;
      flex-direction: column;
      gap: 14px;
    }

    &__details {
      margin: -52px 0 0;
      display: flex;
      align-items: flex-end;
      gap: 15px;
      position: relative;
      z-index: 2;
    }

    &__user-image {
      width: 104px;
      height: 104px;
      border: 3px solid var(--white);
      border-radius: 100%;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

      .user-image {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    display: block;
    margin: 0 0 4px;
  }

  &__list {
    margin: 0 0 8px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 11px;
    }

    svg {
      width: 22px;
      height: auto;
      color: var(--primary-light);
    }
  }

  .button.btn-subscribe {
    background: var(--primary-darker);
    border-color: var(--primary-darker);

    &.sub_active {
      background: none;
      border-color: var(--primary);

      #light_mode & {
        color: var(--primary);
      }

      .btn-tag-item {
        background: none;

        #light_mode & {
          background: none;
        }
      }
      .subscription-btn-text {
        svg {
          display: block;
          width: 16px;
          height: auto;
          margin: 0 10px 0 -3px;
        }
      }
    }
    &.cursor_none {
      pointer-events: none;
    }

    &:hover:not(.sub_active) {
      background: var(--primary);
      border-color: var(--primary);
    }

    .subscription-btn-text {
      display: flex;
      align-items: center;

      svg {
        display: none;
      }
    }

    .btn-tag-item {
      background: var(--secondary);
    }
  }
}
