.custom-checkbox-label.claim-free-tokens {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 19px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    .custom-fake-checkbox {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    visibility: hidden;
    position: absolute;

    &:checked {
      + .custom-fake-checkbox {
        border-color: var(--primary-light);
        background: var(--primary-light);

        #light_mode & {
          border-color: var(--primary-light);
        }

        svg {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .custom-checkbox-text {
    #light_mode & {
      color: var(--default-text-color);
    }
  }
  .custom-fake-checkbox {
    display: block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    #light_mode & {
      border-color: rgba(0, 0, 0, 0.3);
    }

    &:before {
      border: 6px solid #22252a;
      border-radius: 4px;
      position: absolute;
      inset: -8px;
      content: '';
      transition: all 0.4s ease;
      opacity: 0;
      visibility: hidden;

      #light_mode & {
        border-color: rgba(0, 0, 0, 0.3);
      }
    }

    svg {
      width: 14px;
      height: auto;
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease;
      margin: 2px 0 0 2px;
    }
  }
}
