.swiper-custom-modal-overlay {
  position: fixed;
  inset: 0;
  padding: 0;
  background: var(--default-bg) !important;

  .intial_loading_logo {
    max-width: 300px;
    margin: 0 auto;
    display: block;
  }

  .swiper_actions {
    position: relative;
    z-index: 4;
    background: var(--default-bg);
  }

  .block-head {
    .header-left-items {
      gap: 24px;
    }

    .swiper-back {
      width: 24px;
      height: 24px;
      cursor: pointer;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .header-profile-details {
      display: flex;
      gap: 12px;
      align-items: center;

      .profile-image {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
      }
    }

    .swiper-close {
      width: 22px;
      height: 22px;
      cursor: pointer;

      svg {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .swiper-button {
    width: 42px;
    height: 42px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    color: rgba(255, 255, 255, 0.8);
    background: rgba(var(--primary-rgb), 0.5);

    &:after {
      display: none;
    }

    &.swiper-button-prev {
      left: 24px;
    }

    &.swiper-button-next {
      right: 24px;
    }

    svg {
      width: 28px;
      height: auto;
      display: block;
    }
  }

  @media (max-width: 640px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none !important;
    }
  }

  .swiper_overlay {
    position: fixed;
    inset: 0;
    z-index: 3;
    transition: z-index 0.4s ease;
  }

  .swiper-slide {
    &.swiper-slide-zoomed {
      .swiper_overlay {
        z-index: 0;
        transition: none;
      }
    }

    &:not(.swiper-slide-zoomed) {
      .swiper-zoom-container {
        transform: none !important;

        img:not(.imgplaceholder_img) {
          transform: none !important;
        }
      }
    }
  }
}

.swiper-modal {
  --swiper-theme-color: #9b9fa0;
  --swiper-navigation-size: 34px;

  .swiper_overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &.modal-dialog {
    max-width: inherit;
    background: none;
    border: none;
    height: 100%;
  }

  .modal-content,
  .modal-body,
  .swipe-slide,
  .slider_container,
  .swiper {
    height: 100%;
  }

  .swipe-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swipe-slide-holder {
    height: calc(100% - 140px);
    padding: 70px;
    width: 100%;

    @media (max-width: 767px) {
      height: calc(100% - 60px);
      padding: 30px;
    }
  }

  .swiper-pagination {
    display: none !important;
  }

  .audio-holder {
    max-width: 768px;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rhap_container {
    position: relative;
    z-index: 3;
  }

  .video_slide {
    .videoPlayer {
      height: 100%;
    }

    .videoPlayer {
      max-width: 1024px;
      height: 100%;
      margin: 0 auto;
    }

    .react-player {
      position: static;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    video {
      width: auto !important;
      height: auto !important;
      max-width: 100% !important;
      max-height: 100% !important;
      display: block;
      margin: 0 auto;
      position: relative;
      z-index: 3;
    }
  }

  .image_slide {
    .image-comp {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1024px;
      margin: 0 auto;

      img {
        max-height: 100%;
        position: relative;
        z-index: 3;
        display: block;
        margin: 0 auto;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    transition: all 0.4s ease;

    &:hover {
      color: #fff;
    }
  }

  .swiper-button-prev {
    @media (max-width: 767px) {
      left: -2px;
    }
  }

  .swiper-button-next {
    @media (max-width: 767px) {
      right: -2px;
    }
  }

  .swiper_actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
    z-index: 3;

    .swiper-close {
      cursor: pointer;
      color: var(--swiper-theme-color);
      width: 24px;
      transition: all 0.4s ease;
      position: relative;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }

      &:hover {
        color: var(--default-text-color);
      }
    }

    svg {
      cursor: pointer;
    }
  }
}

.rhap_container {
  background: var(--secondary-dark-100);

  .rhap_time {
    color: #fff;
  }
}

.swiper-button {
  width: 42px;
  height: 42px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(122, 49, 111, 0.5);

  &:after {
    display: none;
  }

  &.swiper-button-prev {
    left: 24px;
  }

  &.swiper-button-next {
    right: 24px;
  }

  svg {
    width: 28px;
    height: auto;
    display: block;
  }
}

.swiper-custom-modal {
  &.modal-dialog {
    max-width: inherit;
    background: none;
    border: none;
    height: 100%;
  }

  .swiper-slide {
    &.swiper-slide-active {
      z-index: 2;
    }
  }

  // .swiper_actions {
  //   position: absolute;
  //   right: 15px;
  //   top: 15px;
  //   z-index: 2;

  //   .swiper-close {
  //     width: 24px;
  //     display: block;
  //     cursor: pointer;

  //     svg {
  //       width: 100%;
  //       height: auto;
  //       display: block;
  //     }
  //   }
  // }

  .modal-content,
  .modal-body,
  .swipe-slide,
  .slider_container,
  .swiper {
    height: 100%;
  }

  .rc-vidoe-player {
    width: 100%;
    height: 100%;
  }

  .react-player {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  video {
    width: auto !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 3;
  }

  .audio-holder {
    max-width: 390px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0 0 60px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    z-index: 3;
  }

  .swiper-wrapper {
    height: calc(100% - 288px);
    padding: 0 0 144px;

    @media (max-width: 767px) {
      padding: 40px 0 88px;
      height: calc(100% - 188px);
    }

    &.swiper-wrapper-secondary {
      padding: 0;
      height: calc(100% - 56px);

      .swipe-slide-holder {
        max-width: inherit;
      }

      img.original_image {
        position: static;
        transform: none;
        height: 100% !important;
        width: auto !important;
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .swipe-slide-holder {
    height: 100%;
    width: 100%;
    max-width: 798px;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      // height: calc(100% - 60px);
      // padding: 30px;
    }

    img {
      max-height: 100%;
      position: relative;
      z-index: 3;
      display: block;
      margin: 0 auto;
      width: auto;
      max-width: 100%;

      &.original_image {
        position: absolute;
        left: 50%;
        top: 50%;
        width: auto;
        height: auto !important;
        border-radius: 10px;
        transform: translate(-50%, -50%);
      }
    }

    .audio-fall-back {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  // .image_slide {
  //   .swipe-slide-holder {
  //     .image-comp {
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       border-radius: 10px;
  //       position: relative;
  //       overflow: hidden;

  //       img {
  //         width: 100%;
  //         height: 100%;
  //         object-fit: cover;
  //       }
  //     }
  //   }
  // }

  .swipe-slide-image,
  .swipe-slide-video {
    .image-comp {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1024px;
      margin: 0 auto;

      img {
        max-height: 100%;
        position: relative;
        z-index: 3;
        display: block;
        margin: 0 auto;

        &.imgplaceholder_img {
          // opacity: 0;
          width: auto;
          height: 100%;
        }

        &.original_image {
          position: absolute;
          left: 50%;
          top: 50%;
          width: auto;
          height: auto !important;
          border-radius: 10px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .swipe-slide-audio {
    .image-comp {
      img {
        z-index: -1;
        object-fit: cover;
      }
    }
  }

  .audio_slide {
    .swipe-slide-holder {
      img.original_image {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
      }
      .audio_player {
        display: none;
      }
      .audio_background {
        background: rgba(33, 6, 56, 0.6);
      }
    }
  }

  .swiper-footer-logo {
    width: 50px;
    height: 50px;
    border: 2px solid var(--border-color);
    border-radius: 100%;
    padding: 5px;
    position: absolute;
    left: 50%;
    bottom: 26px;
    transform: translate(-50%, 0);
    z-index: 3;

    @media (max-width: 767px) {
      bottom: 16px;
      width: 36px;
      height: 36px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}
