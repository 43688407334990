.user-image {
  position: relative;
  width: 37px;
  height: 37px;
  background: #000;
  border-radius: 50%;
  .image-comp {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img,
    .img_placeholder_comp {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .img_placeholder_comp {
    width: 100%;
    height: 100%;
  }

  .status {
    position: absolute;
    right: 7px;
    bottom: 2px;
    border: 2px solid var(--white);
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
}

.rounded_img {
  .img_placeholder_comp {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.img_placeholder_comp {
  width: 100%;
  height: 100%;
}
