.post_media {
  position: relative;
  cursor: pointer;

  #light_mode & {
    background: #f8f9f9;
  }

  .image-comp {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .post_play_btn {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    overflow: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    cursor: pointer;

    rect {
      transition: all 0.4s ease;
    }

    &:hover {
      rect {
        fill-opacity: 0.7;
      }
    }
  }
  .audio_thumb {
    max-width: 100%;

    .img-audio {
      &.audio_fallback {
        max-width: 215px;
      }
      display: block;
      margin: 0 auto;

      svg {
        width: 100%;
        height: auto;
        display: block;
        vertical-align: top;

        path {
          fill-opacity: 0.4;
        }
      }
    }
  }
}
