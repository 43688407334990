.profile_card {
  min-height: 130px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  color: #fff;
  font-weight: 400;
  display: flex;
  cursor: pointer;

  &:hover {
    .bg-img {
      transform: scale(1.1);
    }
  }

  .profile_card_wrap {
    min-height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .bg-img {
    position: absolute;
    transition: all 0.4s ease;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .suggested-user-details {
    display: flex;
    align-items: center;
    padding: 6px 12px 6px 136px;
    background: rgba(0, 0, 0, 0.4);
    min-height: 65px;
  }

  .name-area {
    display: flex;
    align-items: center;

    svg {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 5px;
    }
  }

  .image-holder {
    width: 104px;
    min-width: 104px;
    height: 104px;
    border-radius: 100%;
    border: 3px solid #ffffff;
    position: absolute;
    left: 16px;
    bottom: 13px;

    .user-image {
      width: 100%;
      height: 100%;
    }

    img {
      border-radius: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text-holder {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .title {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 16px;
      min-width: 16px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .user-name {
    font-weight: 500;
    color: #d0cbc7;
    opacity: 0.8;
  }

  .action_btns {
    position: absolute;
    right: 10px;
    top: 6px;

    .dropdown-opener {
      padding: 6px;
      transition: all 0.4s ease;
      border-radius: 8px;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      svg {
        display: block;
      }
    }
  }

  .dropdown {
    .dropdown-opener {
      filter: drop-shadow(0px 0px 8px #000000);
    }
  }
}
