.confirmation-modal {
  .button.btn-subscribe {
    // background: var(--primary-darker);
    // border-color: var(--primary-darker);

    #light_mode & {
      // background: var(--primary);
      // border-color: var(--primary);
    }

    .btn-tag-item {
      background: var(--secondary);
    }
  }

  .subscription-heading {
    margin: 4px 0 35px;
  }
}
