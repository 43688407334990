.feeds-slider-item {
  .modal-dialog {
    max-width: inherit;
  }

  .intial_loading_logo {
    max-width: 300px;
    margin: 0 auto;
    display: block;
  }

  .swiper_actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
    z-index: 3;

    .swiper-close {
      cursor: pointer;
      color: var(--swiper-theme-color);
      width: 24px;
      transition: all 0.4s ease;
      position: relative;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }

      &:hover {
        color: var(--default-text-color);
      }
    }

    svg {
      cursor: pointer;
    }
  }

  .image-comp {
    height: 100vh;

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
      z-index: 10;
      position: relative;
    }
  }
  .video_slide {
    .videoPlayer {
      height: 100%;
      .video-pause-icon svg {
        padding: 0;
      }

      video {
        width: auto !important;
        height: auto !important;
        max-width: 100% !important;
        max-height: 100% !important;
        display: block;
        margin: 0 auto;
        position: relative;
        z-index: 3;
      }
    }
  }
  .audio_slide {
    .image-comp img {
      display: block;
      margin: 0 auto;
    }
  }
}
