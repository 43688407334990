html,
body,
#root {
  height: 100%;
}

.h-100 {
  height: 100%;
}
.h-100vh {
  height: 100vh;
}

.space-between {
  justify-content: space-between !important;
}

.flex {
  display: flex;
}
.align-center {
  align-items: center;
}

@media (max-width: 991px) {
  .main-container-wrapper {
    padding: 0 0 60px;

    &.no-footer-menu {
      padding: 0;
    }
  }
}

.block-divider {
  height: 8px;
  background: rgba(255, 255, 255, 0.03);
  border-top: 1px solid var(--border-color);
}

.custom-radio-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 19px;
  color: var(--default-text-color);
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    .custom-fake-radio {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  input[type='radio'] {
    opacity: 0;
    visibility: hidden;
    position: absolute;

    &:checked {
      + .custom-fake-radio {
        border-color: var(--primary-light);
        background: var(--primary-light);

        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .custom-fake-radio {
    display: block;
    width: 22px;
    height: 22px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    position: relative;

    &:before {
      border: 6px solid #22252a;
      border-radius: 100%;
      position: absolute;
      inset: -8px;
      content: '';
      transition: all 0.4s ease;
      opacity: 0;
      visibility: hidden;
    }

    &:after {
      position: absolute;
      border: 2px solid var(--white);
      width: 12px;
      height: 12px;
      border-radius: 100%;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease;
    }
  }
}

.sadava-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  color: #9c9fa1;

  li {
    gap: 8px;
    display: flex;
    position: relative;
    align-items: center;

    &:before {
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translate(0, -50%);
      width: 3px;
      height: 3px;
      border-radius: 100%;
      content: '';
      background: currentColor;
    }
  }
}

.user-profile-image {
  width: 104px;
  height: 104px;
  border: 3px solid var(--default-bg);
  border-radius: 100%;
  position: relative;

  &:hover {
    .profile-image-actions {
      opacity: 1;
      visibility: visible;
    }
  }

  .user-image {
    width: 100%;
    height: 100%;
  }

  .image-comp {
    border-radius: 100%;
    overflow: hidden;

    svg,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .profile-image-actions {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
  }

  .profile-image-actions-item {
    cursor: pointer;
  }
}

.top-divider {
  border-top: 7px solid rgba(255, 255, 255, 0.03);
}

.text-primary {
  color: var(--primary);
  opacity: 1 !important;
}

.text-white {
  color: var(--default-text-color);
}

fieldset {
  border: none;
  display: block;
  padding: 0;
  margin: 0;
}

.auth-error {
  margin: 5px 0 -11px;
}

.form-error,
.error-message {
  /* Add your desired styles for the error state */
  display: flex;
  align-items: center;
  gap: 11px;
  color: #f00;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}

.filters-header-bar {
  display: flex;
  flex-direction: row;
  min-height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;

  &__left {
    text-transform: uppercase;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 10px;

    &__icon {
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: var(--primary-light);
      }
    }
  }
}

.subscription-heading {
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 24px;
  display: block;
}

.social-text-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 8px;
  font-size: 14px;
  line-height: 16px;
}

.social-link-text-item {
  display: flex;
  align-items: center;
  background: rgba(28, 145, 224, 0.2);
  padding: 2px 14px 2px 2px;
  border-radius: 16px;
  gap: 8px;
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover {
    background: rgba(28, 145, 224, 0.4);
  }

  .social-icon-holder {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.instagram {
      background: #f00073;
    }

    &.tiktok {
      background: #000000;
    }

    &.twitch {
      background: #9146ff;
    }
  }
}

.social-circle-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 7px;
}

.ciruclar-social-icon {
  width: 48px;
  height: 48px;
  border: 1px solid var(--border-color);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
  cursor: pointer;

  #light_mode & {
    color: var(--primary);
  }

  .seller_in_collection & {
    path {
      fill: var(--primary-light);
      stroke: var(--primary-light);
    }
  }

  &:hover {
    border: 2px solid var(--primary-light);
  }

  svg {
    width: 24px;
    height: auto;
    display: block;
  }
}

/* seller profile styles */
.seller-header-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.52) 5.95%, rgba(0, 0, 0, 0) 86.37%);
  padding: 9px 17px;
  gap: 15px;

  &:not(.seller-header-fixed) {
    color: var(--white);
  }

  &.seller-header-enter {
    animation: header-animate 0.4s ease;
  }

  &.seller-header-exit {
    animation: header-animate-close 0.4s ease;
  }

  &.seller-header-fixed {
    border-bottom: 1px solid var(--border-color);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9;
    background: var(--default-bg);
    display: flex;
    justify-content: space-between;
    padding: 7px 16px 7px 57px;
    border-bottom: 1px solid var(--border-color);
    gap: 16px;

    &.seller-header-profile {
      margin: 0 0 -56px;
    }
    // transition: position 0.4s ease 0.4s;
    // animation: header-animate 0.4s ease;

    // @media (min-width: 1200px) {
    //   width: calc(100% - 580px);
    //   left: calc(50% - 40px);
    //   right: auto;
    //   transform: translate(-50%, 0);
    // }

    // @media (min-width: 1400px) {
    //   width: 640px;
    //   left: calc(50% - 65px);
    // }

    .social-circle-links {
      position: static;
    }

    .block-head {
      gap: 15px;
    }

    .user-cover-area {
      margin: 0;
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;
      display: flex;
      flex-direction: column;
      gap: 6px;

      .message_section_wrap {
        font-size: 12px;
        line-height: 14px;
        text-transform: none;
        opacity: 0.8;
        text-shadow: none;
      }
    }

    .user-name-holder {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .header-btn-back {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .page-back-link {
    cursor: pointer;
  }

  .component_header {
    width: 100%;

    .block-head {
      padding: 0;
      min-height: inherit;
      border: none;
      width: 100%;
    }
  }
}

.seller-action-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 0;
  list-style: none;

  &__icon {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
      opacity: 1;
      color: var(--primary-light);
      background: rgba(255, 255, 255, 0.1);
    }

    &.active {
      color: var(--primary-light);
      opacity: 1;

      svg {
        path {
          fill: var(--primary-light);
        }
      }
    }

    svg {
      width: 24px;
      height: auto;
      display: block;
    }
  }
}

.seller-profile-wrap {
  position: relative;

  .social-circle-links {
    position: absolute;
    right: 17px;
    bottom: -65px;
  }
}

.post-header-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;

  &_wrap {
    display: flex;
    align-items: center;
    gap: 8px;

    .page-back-link {
      @media (min-width: 992px) {
        display: none;
      }
    }
  }

  .post-header-left-area {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
}

.post-header-tags-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 22px;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    gap: 7px;

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:before {
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translate(0, -50%);
      content: '';
      width: 3px;
      height: 3px;
      border-radius: 100%;
      background: rgba(#fff, 0.6);
    }
  }

  svg {
    width: 16px;
    height: auto;
    display: block;
  }
}

.user-name-default {
  font-size: 18px;
  line-height: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  text-transform: none;

  .user-verified {
    width: 16px;

    svg {
      display: block;
    }
  }
}

.user-bio-info {
  padding: 0 18px 20px 18px;
  position: relative;

  .user-name-default {
    margin: 0 0 3px;
  }

  .user-bio-name {
    margin: 0 0 16px;
  }

  .user-profile-name {
    color: #b5b7b8;
    display: block;
  }

  .user-bio-text {
    position: relative;
    font-size: 16px;
    line-height: 1.375;
    margin: 0 0 14px;

    p {
      margin: 0 0 3px;
    }

    .more-info {
      display: inline-block;
      vertical-align: top;
      color: var(--primary);
      font-size: 13px;
      line-height: 18px;
      cursor: pointer;
    }
  }
}

.link-text {
  color: var(--primary);
  cursor: pointer;
}

.user-cover-area {
  position: relative;
  margin: 0 0 88px;

  @media (max-width: 991px) {
    margin: 0 0 93px;
  }

  .user-profile-image {
    position: absolute;
    left: 23px;
    bottom: -53px;

    @media (max-width: 991px) {
      left: 16px;
      bottom: -57px;
    }
  }

  .user-profile-image {
    border-color: #fff;
    left: 18px;
    bottom: -54px;
    position: absolute;

    .status {
      right: 6px;
      bottom: 4px;
    }
  }
}

.profile-cover-image {
  position: relative;

  .user-cover-image {
    height: 210px;
    position: relative;
    overflow: hidden;

    @media (max-width: 991px) {
      height: 190px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .user-image-actions {
    position: absolute;
    right: 12px;
    bottom: 9px;
    display: flex;
    gap: 11px;

    svg {
      filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.8));
    }
  }

  .user-image-actions-item {
    cursor: pointer;
  }
}

/* seller profile styles end here*/

/* switcher list styles */
.sd-switcher-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 22px;

  li {
    border-bottom: 1px solid var(--border-color);
    padding: 13px 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/* menu heading styles */
.sd-menu-item {
  padding: 13px 21px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid var(--border-color);
}

.pt-20 {
  padding-top: 20px;
}

.full-width-skelton {
  width: 100%;
  height: auto;
  display: block;
}

.singlePost {
  .post_comments_counter .post_comments_counter_handler {
    color: var(--default-text-color);
  }
}

@media (min-width: 992px) {
  .hidden-desktop {
    display: none !important;
  }
}

.col-right {
  @media (max-width: 1040px) {
    .hidden-desktop {
      display: block !important;
    }
  }
}

.empty_notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  height: 100%;

  &_wrap {
    width: 100%;
    padding: 15px;
  }
}

@keyframes header-animate {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes header-animate-close {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.dots-loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
  display: block;
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--default-text-rgb), 1),
      1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.2),
      2.5em 0em 0 0em rgba(var(--default-text-rgb), 0.2),
      1.75em 1.75em 0 0em rgba(var(--default-text-rgb), 0.2),
      0em 2.5em 0 0em rgba(var(--default-text-rgb), 0.2),
      -1.8em 1.8em 0 0em rgba(var(--default-text-rgb), 0.2),
      -2.6em 0em 0 0em rgba(var(--default-text-rgb), 0.5),
      -1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--default-text-rgb), 0.7),
      1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 1),
      2.5em 0em 0 0em rgba(var(--default-text-rgb), 0.2),
      1.75em 1.75em 0 0em rgba(var(--default-text-rgb), 0.2),
      0em 2.5em 0 0em rgba(var(--default-text-rgb), 0.2),
      -1.8em 1.8em 0 0em rgba(var(--default-text-rgb), 0.2),
      -2.6em 0em 0 0em rgba(var(--default-text-rgb), 0.2),
      -1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--default-text-rgb), 0.5),
      1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.7),
      2.5em 0em 0 0em rgba(var(--default-text-rgb), 1),
      1.75em 1.75em 0 0em rgba(var(--default-text-rgb), 0.2),
      0em 2.5em 0 0em rgba(var(--default-text-rgb), 0.2),
      -1.8em 1.8em 0 0em rgba(var(--default-text-rgb), 0.2),
      -2.6em 0em 0 0em rgba(var(--default-text-rgb), 0.2),
      -1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--default-text-rgb), 0.2),
      1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.5),
      2.5em 0em 0 0em rgba(var(--default-text-rgb), 0.7),
      1.75em 1.75em 0 0em rgba(var(--default-text-rgb), 1),
      0em 2.5em 0 0em rgba(var(--default-text-rgb), 0.2),
      -1.8em 1.8em 0 0em rgba(var(--default-text-rgb), 0.2),
      -2.6em 0em 0 0em rgba(var(--default-text-rgb), 0.2),
      -1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--default-text-rgb), 0.2),
      1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.2),
      2.5em 0em 0 0em rgba(var(--default-text-rgb), 0.5),
      1.75em 1.75em 0 0em rgba(var(--default-text-rgb), 0.7),
      0em 2.5em 0 0em rgba(var(--default-text-rgb), 1),
      -1.8em 1.8em 0 0em rgba(var(--default-text-rgb), 0.2),
      -2.6em 0em 0 0em rgba(var(--default-text-rgb), 0.2),
      -1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--default-text-rgb), 0.2),
      1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.2),
      2.5em 0em 0 0em rgba(var(--default-text-rgb), 0.2),
      1.75em 1.75em 0 0em rgba(var(--default-text-rgb), 0.5),
      0em 2.5em 0 0em rgba(var(--default-text-rgb), 0.7),
      -1.8em 1.8em 0 0em rgba(var(--default-text-rgb), 1),
      -2.6em 0em 0 0em rgba(var(--default-text-rgb), 0.2),
      -1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--default-text-rgb), 0.2),
      1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.2),
      2.5em 0em 0 0em rgba(var(--default-text-rgb), 0.2),
      1.75em 1.75em 0 0em rgba(var(--default-text-rgb), 0.2),
      0em 2.5em 0 0em rgba(var(--default-text-rgb), 0.5),
      -1.8em 1.8em 0 0em rgba(var(--default-text-rgb), 0.7),
      -2.6em 0em 0 0em rgba(var(--default-text-rgb), 1),
      -1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(var(--default-text-rgb), 0.2),
      1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 0.2),
      2.5em 0em 0 0em rgba(var(--default-text-rgb), 0.2),
      1.75em 1.75em 0 0em rgba(var(--default-text-rgb), 0.2),
      0em 2.5em 0 0em rgba(var(--default-text-rgb), 0.2),
      -1.8em 1.8em 0 0em rgba(var(--default-text-rgb), 0.5),
      -2.6em 0em 0 0em rgba(var(--default-text-rgb), 0.7),
      -1.8em -1.8em 0 0em rgba(var(--default-text-rgb), 1);
  }
}

.thumb-vertical {
  #light_mode & {
    background-color: #000 !important;
  }
}

.cards-brand-list {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  gap: 6px;
  justify-content: center;

  .card-brand-image {
    width: 33px;
    border-radius: 3px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

.card-footer-info {
  text-align: center;
  color: #9c9fa1;
  font-size: 13px;
  line-height: 22px;

  .button-add-payment {
    .button {
      min-height: 36px;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 18px;
      padding: 4px 10px;

      svg {
        width: 18px;
      }
    }
  }
}

/* swal modal styles */
div:where(.swal2-container) {
  div:where(.swal2-popup) {
    background: var(--default-bg) !important;
    color: var(--default-text-color) !important;

    div:where(.swal2-icon).swal2-info {
      border-color: var(--primary);
      color: var(--primary);
    }
  }

  button:where(.swal2-styled).swal2-confirm {
    background: var(--primary) !important;
    transition: all 0.4s ease;

    &:hover {
      background: var(--btn-hover) !important;
    }
  }

  .swal2-html-container {
    font-size: 14px !important;
    line-height: 1.3 !important;
  }
}
