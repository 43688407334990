.swiper-carousel {
  // padding-bottom: 32px;
  // max-width: 1200px;

  .swiper-wrapper {
    align-items: center;
    height: calc(100% - 56px);
  }

  .swiper-slide {
    position: relative;
    // width: 640px;
    width: 560px;
    height: 100%;
    max-height: 842px;
    border-radius: 10px;
    // background: #18212b;
    max-width: calc(100% - 48px);

    &:not(.swiper-slide-active) {
      video {
        pointer-events: none;
      }
    }

    &.swiper-slide-active {
      &:hover {
        .image-comp {
          transform: scale(1.05);
        }
      }

      .swiper-play-btn {
        opacity: 1;
      }
    }
  }

  .swiper-play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    z-index: 3;
    opacity: 0;
  }

  .image-comp {
    transition: all 0.4s ease;

    img {
      transition: none;
    }
  }

  .swipe-slide-video {
    .image-comp {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        // filter: blur(13.5px);
      }
    }
  }

  .swiper-carousel-animate-opacity {
    height: 100%;
    opacity: 1 !important;
  }

  .swiper-pagination-bullets {
    bottom: 0;
  }

  img {
    // width: 100%;
    // height: 100%;
    // display: block;
    // object-fit: cover;
    // border-radius: 8px;
  }

  .swipe-slide-holder {
    height: calc(100% - 140px);
    padding: 70px;
    width: 100%;
    position: relative;

    @media (max-width: 767px) {
      height: calc(100% - 60px);
      padding: 30px;
    }
  }

  .slide-content {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .audio_player {
    display: none;
  }
  .audio_background {
    background: rgba(33, 6, 56, 0.6);
  }
}

.audio_player_content {
  max-width: 390px;
  width: 100%;
  padding: 16px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--white);

  &.align-bottom {
    align-self: flex-end;
    margin-bottom: 50px;
    pointer-events: none;
  }

  &.position-absolute {
    position: absolute;
    z-index: 12;
    left: 50%;
    bottom: 27.2%;
    margin: 0 0 0 -211px;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    gap: 35px;
  }

  &__left {
    display: flex;
    gap: 16px;
    align-items: center;
    min-width: 0;
  }

  &_duration {
    font-size: 16px;
    padding: 6px 0 0;
  }

  .player_button {
    width: 50px;
    min-width: 50px;
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
      color: rgba(225, 52, 109, 0.8);
    }

    svg {
      width: 100%;
      display: block;
      height: auto;
    }
  }

  .audio_title {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .title {
    display: block;
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .username {
    display: block;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .audio_player_bar {
    height: 4px;
    position: relative;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
  }

  .audio_player_bar_progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    background: var(--white);
  }
}
