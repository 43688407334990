.image-comp {
  position: relative;
  width: 100%;
  height: 100%;
  .intial_loading_logo {
    position: absolute;
    z-index: -1;
  }
  &.rounded_img {
    img {
      border-radius: 100%;
    }
    .intial_loading_logo {
      border-radius: 100%;
    }
  }

  .original_image {
    height: 100% !important;
    width: 100%;
    // transition: opacity 0.4s ease;

    /* Add the blur effect */
    // filter: blur(1px);
    // -webkit-filter: blur(1px);
  }
  .imgplaceholder_img {
    opacity: 0;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.4s ease;
  }
  &.loading {
    .original_image {
      opacity: 0;
    }
    .imgplaceholder_img {
      opacity: 1;
    }
  }

  img {
    max-width: 100%;
  }

  &.defaultImage {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain !important;

      &.original_image {
        object-fit: cover !important;
      }
    }
  }
  .imgplaceholder_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.slider_img_placeholder {
    .imgplaceholder_img {
      width: auto;
      left: 50%;
      max-width: 100%;
      transform: translateX(-50%);
    }
  }
}
