#root {
  &.body-default-scroll {
    overflow: visible;
    height: auto;

    .page-scroll-handler & {
      @media (max-width: 991px) {
        overflow: hidden;
        height: 100%;
      }
    }

    .has-scroll & {
      @media (max-width: 991px) {
        overflow: visible;
      }
    }
  }
}

.home_wrapper,
.notification_wrapper,
.signle_post_container {
  .component_header {
    position: sticky;
    top: 0;
    z-index: 3;
    background: var(--default-bg);
  }
}

@media (min-width: 992px) {
  .body-default-scroll {
    .app_sidebar,
    .subscriptions-middle-view {
      position: sticky;
      top: 0;
      height: 100vh;
    }

    .home_right_wrapper,
    .notification_right_wrapper,
    .seller-right-area,
    .singlepost_right_wrapper {
      position: sticky;
      top: 0;
      height: 100vh;
    }

    .notification_middle_scroll_wrapper {
      display: block;
      height: auto;
    }
  }
}

@media (max-width: 991px) {
  .home_middle_scroll_wrapper {
    // padding-bottom: 65px;
  }
}
