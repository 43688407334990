.authpage-scroll {
  .rc-scollbar {
    min-height: 100%;
    display: flex;
  }
}

.sign_page {
  display: flex;
  width: 100%;
  background: #000;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }

  #light_mode & {
    background: #fff;
  }

  .auth-container {
    max-width: calc(100% - 670px);
    border-right: 2px solid var(--border-color);
    width: 50%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;

    @media (max-width: 1023px) {
      max-width: 100%;
      width: 100%;
      border-right: none;
      border-bottom: 2px solid var(--border-color);
      position: static;
    }

    .signin-custom-scroll {
      @media (max-width: 1023px) {
        position: static !important;
        overflow: visible !important;
        width: 100% !important;
        height: auto !important;
      }

      > div {
        &:first-child {
          @media (max-width: 1023px) {
            position: static !important;
            overflow: visible !important;
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          @media (max-width: 1023px) {
            display: none !important;
          }
        }
      }
    }

    .rc-scollbar {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      position: relative;

      .auth-listing-link {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
      }
    }
  }

  .signup_scroll_wrapper {
    width: 50%;
    background: #0c0c0d;
    min-width: 670px;
    padding: 0 0 24px;
    margin: 0 0 0 auto;

    @media (max-width: 1023px) {
      width: 100%;
      min-width: inherit;
    }

    #light_mode & {
      background: #f6f8fa;

      @media (max-width: 1023px) {
      }
    }
  }

  .signup_scroll_container {
    max-width: 670px;
    margin: 0 auto;
    padding: 30px 15px;
    overflow: hidden;
    position: relative;
  }

  .block-heading {
    display: block;
    font-size: 22px;
    line-height: 1.364;
    margin: 0 0 30px;
  }

  .auth-content {
    margin: auto;
    padding: 60px 15px;

    &.signup-page {
      padding: 15px;
    }
  }

  .post_wrapper {
    background: #181819;
    border-radius: 8px;
    margin: 0 0 36px;
    overflow: hidden;

    #light_mode & {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.13);
      background: var(--white);
    }
  }
}

.auth-back-arrow {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 36px;
  height: 36px;
  border: 1px solid rgba(#8f9aa7, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: var(--primary);
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover {
    background: rgba(#8f9aa7, 0.2);
  }
}

.auth-container {
  display: flex;
  width: 100%;
  min-height: inherit;
  align-items: center;
  justify-content: center;

  &.full-height {
    background: var(--default-bg);
    .auth-content {
      margin: auto;
      padding: 60px 15px;
    }
  }

  .button {
    text-transform: uppercase;
  }
}

.auth-content {
  max-width: 390px;
  padding: 15px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 25px 15px 46px;
  }

  &__heading-text {
    font-size: 22px;
    line-height: 1.364;
    margin: 0 0 38px;
    padding: 0 30px 0 0;
  }
}

.title-margin {
  &.has-color {
    h3 {
      color: var(--primary);
    }
  }

  h3 {
    font-size: 18px;
    line-height: 1.667;
    margin: 0 0 22px;
    font-weight: 500;
  }
  .verify-text {
  }
}

.login_with_app {
  padding-top: 39px;

  .auth-buttons {
    margin: 0 0 16px;
  }
}

.auth-logo-wrap {
  margin: 0 0 40px;
  max-width: 300px;
  font-size: 22px;
  line-height: 1.364;

  @media (max-width: 767px) {
    margin: 0 0 60px;
  }

  &.mb-0 {
    margin: -24px 0 4px;
  }

  p {
    @media (max-width: 1023px) {
      margin: 0;
    }
  }
}

.login-logo {
  width: 300px;
  margin: 0 0 16px;
  display: block;
  font: 44px/1 'Neon', Arial, Helvetica, sans-serif;
  color: var(--primary-light);

  svg {
    width: 100%;
    height: auto;
    display: block;
  }
}

.auth-title {
  font-size: 16px;
  line-height: 19px;
  color: var(--light-gray-300);

  h3 {
    margin: 0 0 15px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: var(--default-text-color);
  }

  p {
    margin: 0 0 30px;
  }
}

.auth-form {
  margin: 0 0 36px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  h5 {
    margin: 0;
  }

  .password-reset {
    text-align: right;
    color: var(--primary);
    padding: 8px 16px;
    font-size: 12px;
    line-height: 15px;
  }
  .password_icon {
    color: var(--default-text-color);
    opacity: 0.6;
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
      opacity: 1;
    }
  }
}

.auth-buttons {
  margin: 0 0 36px;
  display: flex;
  justify-content: center;
  gap: 16px;
  .button:not(:disabled) {
    // background: rgba(28, 145, 224, 0.8);
    &.outline {
      background: none;
      color: var(--white);

      &:hover {
        background: var(--secondary-dark-100);
      }

      #light_mode & {
        border-color: #4285f4;
        color: #1da1f2;
      }
    }
  }

  .button {
    background: #61b2ea;

    &:hover {
      background: #1c91e0;
    }

    &:disabled {
      background: var(--border-color);
      color: #968894;

      #light_mode & {
        background: #e0e3e4;
        color: #fff;
      }
    }
    &.lefticon {
      padding-left: 38px;
      padding-right: 38px;
      position: relative;
      font-size: 13px;
    }
    .lefticon {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translate(0, -50%);
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.auth-list-helper {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  li {
    position: relative;

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:before {
      position: absolute;
      width: 3px;
      height: 3px;
      border-radius: 100%;
      left: -17px;
      top: 50%;
      transform: translate(0, -50%);
      content: '';
      background: var(--secondary-link-color);
    }
  }

  .auth-list-helper_link {
    color: var(--secondary-link-color);
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
      color: var(--primary-light);
    }
  }
}

.login-pages-scroll {
  @media (max-width: 1023px) {
    background: #f6f8fa;
  }

  #main-container-wrapper {
    padding: 0;
  }
}

.post-button-center {
  text-align: center;
  padding: 24px 0 0;

  .button {
    min-height: 40px;
  }
}

.social-form {
  position: relative;
  overflow: hidden;
  padding: 32px 0 35px;
  margin: 27px 0 0;

  &:before {
    position: absolute;
    left: 0;
    top: -3px;
    height: 4px;
    background: var(--border-color);
    content: '';
    right: 0;
  }

  .title-margin {
    margin: 0 0 24px;

    h3 {
      margin: 0 0 6px;
      font-size: 16px;
    }
  }

  .subtitle {
    color: var(--default-text-color);
    opacity: 0.4;
  }

  .ico-input {
    .form-control {
      padding-left: 48px;
    }

    .icon {
      width: 22px;
      height: 22px;
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    label {
      left: 48px;
    }
  }

  .auth-input {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.auth_section_posts {
  .post_wrapper {
    border: none;

    &:after {
      display: none;
    }
  }
}
