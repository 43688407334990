.page_skeleton {
  display: flex;
  flex-direction: row;
}

.skelton_middle_wrapper:not(.no-space) {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;

  [aria-labelledby='loading-aria'] {
    padding: 16px;
  }
}

.skelton_right_wrapper {
  width: 430px;
  min-width: 430px;
  border-left: 1px solid var(--border-color);
  overflow: hidden;

  @media (max-width: 1199px) {
    width: 320px;
    min-width: 320px;
  }
}

.skelton_right_scroll_wrapper {
  padding: 16px 30px;

  @media (max-width: 1199px) {
    padding: 15px;
  }

  [aria-labelledby='loading-aria'] {
    margin: 0 0 16px;
    width: 100%;
    height: auto;
    display: block;
  }
}
