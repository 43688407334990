.text-input {
  position: relative;

  &.affix-icon {
    .form-control {
      padding-right: 45px;
    }
  }

  .rightIcon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    color: #797d80;

    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &.materialized-input {
    label {
      position: absolute;
      padding: 0 3px;
      font-size: 16px;
      line-height: 19px;
      color: var(--label-color);
      left: 14px;
      top: 14px;
      transition: all 0.4s ease;
      background: var(--default-bg);
      pointer-events: none;
    }

    &.input-active {
      label {
        top: -7px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .error-msg {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    text-align: right;
    color: #f00;
    font-size: 12px;
    line-height: 14px;
    margin: 5px 0 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;

    + label,
    ~ label {
      top: -7px;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.form-control {
  background: none;
  padding: 11px 17px;
  border: 1px solid var(--input-outline);
  width: 100%;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  border-radius: 6px;
  display: block;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    border-color: var(--primary-light);

    + label,
    ~ label {
      color: var(--primary-light);
    }
  }

  &:not(textarea) {
    height: 48px;
  }

  &::placeholder {
    color: #a7a8aa;
  }
}

.form-word-count {
  font-size: 12px;
  line-height: 14px;
  color: #9c9fa1;
  text-align: right;
  margin: 8px 0 0;
}

.form-url-link {
  font-size: 12px;
  line-height: 14px;
  color: var(--primary-light);
  display: block;
  padding: 6px 18px 0 18px;
  cursor: pointer;
}
