.post_header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 10px;
}

.post_header__personInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px;
}

.post_header_image {
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.post_person_details {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .username {
    color: var(--light-gray-100);
    cursor: pointer;
    transition: all 0.4s ease;
    align-self: flex-start;

    &:hover {
      color: var(--link-hover);
      text-decoration: underline;
    }
  }
}

.post_user_details {
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  cursor: pointer;
  color: inherit;

  &:hover {
    .name {
      text-decoration: underline;
    }
  }

  .post_verified_badge {
    width: 16px;

    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

.post_header__details {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 6px 0 0;

  .time {
    line-height: 23px;
    color: var(--light-gray);
  }

  .dropdown-opener {
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 -5px 0 0;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }

    svg {
      display: block;
    }
  }
  .star-image {
    cursor: pointer;
    transition: all 0.4s ease;
    width: 24px;
    position: relative;

    &.star-image-liked {
      path {
        stroke: var(--primary-light);
      }
    }

    &:before {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: var(--secondary-dark-100);
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease;
    }

    &:hover {
      color: var(--primary-light);

      &:before {
        opacity: 1;
        visibility: visible;
      }

      svg {
        // fill: var(--link-hover);
      }
    }

    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
