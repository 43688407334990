.app_sidebar {
  width: 300px;
  min-width: 300px;
  border-right: 1px solid var(--border-color);
  // transition: all 0.4s ease;

  // @media (max-width: 1399px) {
  //   width: 240px;
  //   min-width: 240px;
  // }

  @media only screen and (max-width: 1399px) and (min-width: 992px) {
    width: 80px;
    min-width: 80px;
  }

  @media (max-width: 991px) {
    width: 300px;
    min-width: 300px;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 61px;
    z-index: 3;
    background: #000;
    height: auto !important;

    #light_mode & {
      background: #fff;
    }
  }

  &:before {
    position: fixed;
    left: 0;
    right: 300px;
    top: 0;
    bottom: 60px;
    content: '';
    background: rgba(0, 0, 0, 0.6);
    display: none;

    @media (max-width: 991px) {
      display: block;
    }
  }

  &.mobile_sidebar_close {
    transform: translate(100%, 0);
  }

  .sidebar-extra-content {
    padding: 25px 0 0 0;

    @media (max-width: 991px) {
      padding: 28px 21px 0;
    }

    @media only screen and (max-width: 1399px) and (min-width: 992px) {
      padding: 25px 14px 0;
    }
  }

  .button {
    padding: 0 40px;
    gap: 0;

    @media only screen and (max-width: 1399px) and (min-width: 992px) {
      padding: 0;
    }

    .lefticon {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0, -50%);

      @media only screen and (max-width: 1399px) and (min-width: 992px) {
        position: static;
        transform: none;
        width: 50px;
      }

      svg {
        @media (max-width: 1399px) {
          margin: 0 auto;
        }
      }
    }

    .btn-text {
      @media only screen and (max-width: 1399px) and (min-width: 992px) {
        display: none;
      }
    }
  }
}

.site-logo {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 16px 0 12px 14px;
  font: 32px/1 'Neon', Arial, Helvetica, sans-serif;
  font-weight: 400;

  width: 146px;

  @media (max-width: 1399px) {
    width: 16px;
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    width: 146px;
    margin: 0;
  }

  a {
    display: block;
    // width: 96px;
    color: var(--primary-light);
  }

  svg {
    display: block;
    height: auto;

    width: 146px;

    text {
      font-size: 18px;
    }
  }
}

.app_sidebar_scroll-wrapper {
  padding: 14px 20px;

  @media (max-width: 1399px) {
    // padding: 14px 20px;
  }

  @media (max-width: 1399px) {
    padding: 14px 0;
  }
}

.side-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .list-item {
    @media (max-width: 1399px) {
      padding: 0 19px;
    }

    a {
      display: block;
      padding: 6px 8px;
      border-radius: 30px;
      align-items: center;
      color: var(--sidemenu-links);

      @media (max-width: 1399px) {
        padding: 6px 0;
      }

      @media (max-width: 991px) {
        padding: 6px 8px;
      }

      &:hover {
        background: var(--border-color);
        color: var(--sidemenu-active-links);

        #light_mode & {
          background: none;
        }
      }
      &.active_nav_item {
        color: var(--sidemenu-active-links);
      }
    }
  }

  .menu-profile {
    padding: 0 0 8px;

    @media (max-width: 1399px) {
      padding: 0 0 12px;
    }

    a {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      overflow: hidden;
      display: block;

      @media (max-width: 1399px) {
        margin: 0 auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .menu-icon {
    width: 28px;
    position: relative;

    svg {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }

    .counter-badge {
      position: absolute;
      width: 20px;
      height: 20px;
      background: var(--primary);
      color: var(--white);
      border: 2px solid var(--white);
      border-radius: 100%;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      text-align: center;
      right: -8px;
      top: -4px;
    }
  }

  .menu-title {
    @media only screen and (max-width: 1399px) and (min-width: 992px) {
      display: none;
    }
  }

  .sidebar_item {
    display: flex;
    align-items: center;
    gap: 22px;

    @media only screen and (max-width: 1399px) and (min-width: 992px) {
      gap: 0;
      justify-content: center;
    }
  }
}

.side-logo {
  display: block;
  width: 50px;

  @media (max-width: 1399px) {
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    margin: 0;
  }

  svg {
    width: 100%;
    height: auto;
    display: block;
  }
}
