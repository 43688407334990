.first-billing-method-form {
  .form-control {
    padding: 15px 17px;
  }

  .modal-scroll-wrap {
    display: flex;
    flex-direction: column;
    gap: 23px;
  }

  .cols {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .text-input {
      flex: 1;
      flex-basis: 0;
      min-width: 0;
      position: relative;
    }

    .img {
      position: absolute;
      right: 13px;
      top: 50%;
      transform: translate(0, -50%);
      width: 33px;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}
