.mobile-menu-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid var(--border-color);
  padding: 16px 25px;
  background: var(--default-bg);
  z-index: 4;
}

.mobile_menu_header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0 13px 12px 16px;
  margin: 0 0 7px;

  .menu_name {
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    margin: 0 0 3px;

    .name-verified {
      display: block;
      width: 14px;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .menu_username {
    font-size: 14px;
    line-height: 17px;
    color: #a3a3a3;
    margin: 0 0 16px;
  }
}

.menu_fans_following {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;

  li {
    position: relative;

    &:before {
      position: absolute;
      left: -12px;
      top: 50%;
      width: 3px;
      height: 3px;
      transform: translate(0, -50%);
      border-radius: 100%;
      content: '';
      background: var(--light-gray-200);
    }
  }
}

.mobile_menu_header_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 18px;

  .mobile_image_user {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .menu-close {
    width: 20px;
    height: 20px;
    cursor: pointer;

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.mobile-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    display: block;
    align-items: center;
    color: var(--default-text-color);

    .mobile_sidebar_open & {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover,
    &.active_nav_item {
      color: var(--primary-light);
    }
  }

  .menu-icon {
    width: 28px;
    position: relative;

    svg {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }

    .counter-badge {
      position: absolute;
      width: 20px;
      height: 20px;
      background: var(--primary);
      color: var(--white);
      border: 2px solid var(--white);
      border-radius: 100%;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      text-align: center;
      right: -8px;
      top: -4px;
    }
  }

  .sidebar_item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-user-profile-image {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    overflow: hidden;
  }
}
