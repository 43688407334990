.card-modal-wrap {
  padding: 20px;
}
.claim-free-tokens-modal {
  .modal-scroll-wrap {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .sub-title {
    color: rgba(255, 255, 255, 0.7);
    padding-left: 16px;
    padding-right: 16px;

    #light_mode & {
      color: var(--default-text-color);
    }
  }
  .modal-footer {
    flex-direction: column;
    padding-top: 29px;
    .terms-link {
      color: #008ccf;
    }
    .btn-subscribe {
      &:hover {
        background: #177abb;
      }
    }
    .btn-subscribe {
      .circular-loader {
        left: auto;
        right: 10px;
        .loader span {
          // border-color: transparent var(--white) var(--white);
          border-width: 3px;
          width: 40px !important;
          height: 40px !important;
          left: auto;
        }
      }
    }
  }
}
.claim-free-tokens {
  .modal-header-wrap {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .header-img {
    position: relative;
    width: 100%;
    height: 254px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .subscription-heading {
    color: rgba(255, 255, 255, 0.8);
    line-height: 22px;
    font-size: 16px;
    b {
      font-weight: 500;
      color: #ffffff;
      #light_mode & {
        color: #000;
      }
    }
    #light_mode & {
      color: rgba(0, 0, 0, 0.8);
    }
    span,
    p {
      margin: 0 auto;
      width: 90%;
      font-size: 14px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.6);
      #light_mode & {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  &.last-step {
    .header-text,
    .body {
      width: 90%;
    }
    .blue {
      color: #04b3f3;
    }
    .bold {
      font-weight: bold;
    }
    .header-text {
      line-height: 22px;

      h5 {
        font-size: 16px;
        margin: 0;
      }
      p {
        margin: 0;
      }
      .sub-header {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        #light_mode & {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .terms-container {
      overflow: hidden;
      position: relative;
      border-radius: 6px;
      border: 1px solid;
      border-color: rgba(143, 153, 166, 0.5);
      padding: 9px 13px 14px 12px;
      display: flex;
      align-items: start;
      gap: 5px;
      .blue-padding {
        position: absolute;
        width: 24px;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(27, 144, 222, 0.2);
      }
      svg {
        min-width: 24px;
        color: #000;
        #light_mode & {
          color: #fff;
        }
      }
      p {
        margin: 0;
        margin-top: 2px;
        line-height: 22px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        #light_mode & {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .text {
      line-height: 22px;
      font-size: 16px;
      color: #fff;
      #light_mode & {
        color: #000;
      }
    }
    .body {
      margin-bottom: 26px;
    }
  }
}
