.main-container {
  max-width: 1370px;
  margin: 0 auto;

  @media (max-width: 1399px) {
    max-width: 1150px;
  }

  @media (max-width: 1199px) {
    max-width: 1040px;
  }

  @media (max-width: 1040px) {
    max-width: 720px;
  }

  @media (max-width: 991px) {
    max-width: 640px;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
  }
}

.app_layout {
  display: flex;
  // color: var(--default-text-color);
}

.app-right-content {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;

  @media (max-width: 1040px) {
    border-right: 1px solid var(--border-color);
  }

  @media (max-width: 991px) {
    border-right: none;
  }
}
