.ReactModal__Body--open {
  overflow: hidden;

  &.content-scroll-preventing {
    padding-right: 16px;
  }
}

.modal-header-wrap {
  padding: 16px;
  text-transform: uppercase;
  font-weight: 500;

  &.has-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 11px 16px;
  }

  &.has-border {
    border-bottom: 1px solid var(--border-alt-color);

    #light_mode & {
      border-color: var(--border-color);
    }
  }
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.modal-dialog {
  outline: none !important;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background: var(--default-bg);
  border-radius: 8px;
  // border: 1px solid var(--border-color);

  @media (max-width: 767px) {
    max-width: 342px;
  }

  .text-input.materialized-input label {
    background: var(--default-bg);
  }
}

.modal-close {
  cursor: pointer;
}

.modal-scroll-wrap {
  padding: 16px;

  &.pt-0 {
    padding-top: 0;
  }

  &.pt-10 {
    padding-top: 10px;
    padding-bottom: 0;
  }
}

.modal-footer {
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 16px;

  &.text-right {
    justify-content: flex-end;
  }

  &.has-space {
    padding: 11px 8px;
  }

  &.small-gap {
    gap: 24px;
  }

  .button {
    padding: 7px 14px;
    color: var(--link-hover);
    border-radius: 16px;

    &.has-space {
      padding: 10px 8px;
    }

    &.modal-btn-cancel {
      opacity: 0.6;
    }

    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      opacity: 1;
    }
  }
}

.credits-modal {
  .rc-scollbar {
    min-height: inherit;
    display: flex;
    flex-direction: column;
  }

  .modal-scroll-wrap {
    min-height: inherit;
    display: flex;
    flex-direction: column;
  }

  .credits-modal-scroll-holder {
    height: 360px;
  }

  .token-heading-area {
    position: relative;
    overflow: hidden;
    font-size: 16px;
    line-height: 19px;

    p {
      margin: 0 0 27px;
    }

    .token-heading-title {
      display: block;
      line-height: 22px;
      color: var(--light-gray-300);
      margin: 0 0 17px;
      font-weight: 500;
    }
  }

  .circular-loader_wrap {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .credit-info-message {
    height: 328px;
    max-width: 311px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  .credit-info-message_wrap {
    display: flex;
    flex-direction: column;
    gap: 27px;
  }
}

.token-purchase-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.credits-list-item {
  height: 50px;
  border: 1px solid #393e43;
  border-radius: 6px;
  background: #10161b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  cursor: pointer;
  transition: all 0.4s ease;

  #light_mode & {
    background: #fff;
  }

  &.active {
    border-color: var(--primary-light);
    background: var(--secondary-dark-100);

    .credits-list-item__left {
      color: var(--primary-light);
    }

    .credits-list-item__right {
      color: var(--default-text-color);
    }

    &:hover {
      border-color: var(--default-text-color);
    }
  }

  &:not(.active):hover {
    background: var(--primary-light);
    border-color: var(--primary-light);

    .credits-list-item__right {
      color: var(--default-text-color);
    }

    .custom-checkbox-label .custom-fake-checkbox {
      border-color: var(--default-text-color);
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 14px;
    font-weight: 500;

    svg {
      width: 24px;
      height: auto;
      display: block;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #9fa2a4;
  }
}
