.locked-post-item {
  position: relative;
  background: var(--secondary-dark);
  padding: 16px;

  .locked-image-holder {
    height: 211px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991px) {
      height: 102px;
    }

    svg {
      @media (max-width: 991px) {
        width: 60px;
        height: auto;
      }

      path {
        fill-opacity: 0.1;
        fill: var(--default-text-color);
      }
    }
  }

  .post-subscriber-block {
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;

    .button {
      @media (max-width: 991px) {
        min-height: 40px;
      }
    }
  }

  .lock-bg-image {
    position: absolute;
    inset: 0;
    padding: 0 5%;
    z-index: -1;
    pointer-events: none;

    img {
      margin: 0 auto;
      display: block;
    }
  }
}

.post-subscriber-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: hidden;

  li {
    gap: 8px;
    display: flex;
    position: relative;

    &:before {
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translate(0, -50%);
      width: 3px;
      height: 3px;
      border-radius: 100%;
      content: '';
      background: #fff;
      opacity: 0.8;
    }
  }
}
