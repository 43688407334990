.post-wrapper-block,
.post_wrapper {
  border-bottom: 8px solid var(--secondary-dark);
  position: relative;
  &:hover {
    .post_media img {
      transform: scale(1.1);
    }
  }
  .post_media img {
    transition: all 0.4s ease;
  }
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    background: var(--border-color);
  }

  .pre-loader {
    position: absolute;
  }
}

.feeds-profile-card {
  padding: 16px 16px 0;

  .profile_card {
    .action_btns {
      right: 11px;
    }
  }
}

.post_top_area {
  padding: 17px 18px;

  .user-bio-text {
    .post_text {
      font-size: 14px;
      line-height: 23px;
    }

    p {
      margin: 0;
    }

    .more-info {
      display: inline-block;
      vertical-align: top;
      margin: 4px 0 0;
      font-size: 13px;
      line-height: 17px;
      color: var(--secondary-link-color);
      cursor: pointer;

      &:hover {
        color: var(--primary);
      }
    }
  }
}

.post_text {
  font-size: 16px;
  line-height: 23px;

  a {
    color: var(--link-hover);

    &:hover {
      text-decoration: underline;
    }
  }
}

.post_footer_wrap {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 991px) {
    padding: 11px 10px;
  }

  &.disable_footer_actions {
    .postLeftOptions_list {
      opacity: 0.6;
      pointer-events: none;
      user-select: none;
    }
  }
}
